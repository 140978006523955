<template>
    <div class="top-btns-bar-right-section">
        <v-text-field
            v-model="_search"
            append-icon="fas fa-search"
            :label="$t('Что_ищем')"
            hide-details
            class="search-in-tooltip"
            solo
            flat
            clearable
        ></v-text-field>

        <div 
            v-if="hasFilter"
            class="extend-filter"
            @click="$emit('on-filter-click')"
        >
            <v-icon
                v-tooltip.left-center="$t('Расширенный_поиск')"
                left
            >
                mdi-filter-variant
            </v-icon> 
        </div>

    </div>
</template>

<script>
export default {
    name: "SearchPanel",
    props: {
        search: {
            type: String,
            default: null
        },
        hasFilter: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        _search: {
            get: function() {
                return this.search;
            },
            set: function(newValue) {
                this.$emit('input', newValue)
            }
        }
    }
}
</script>