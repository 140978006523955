<template>

    <div v-if="filterDataSource" class="p-relative">

        <Toolbar
            :is-list="true"
            v-on:toolbar-button-click="onToolbarClick"
        >
            <!--Панель поиска-->
            <SearchPanel
                v-model="search"
                :has-filter="false"
            />
        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="category"
        >
        </FilterPanel>

        <v-row no-gutters>
            <v-col cols="12" xs="12" md="12">
                <v-data-table
                    :headers="headers"
                    :loading="loading"
                    :items="projects"
                    :options.sync="options"
                    :server-items-length="total"
                    :items-per-page="10"
                    :footer-props="{ itemsPerPageOptions:[ 5, 10, 20] }"
                    :page.sync="options.page"
                    @page-count="paginationPageCount = $event"
                    class="elevation-1 customization-list-table pagination-list-table sticky-table"
                    @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents.Document', common: data.item.iscommon })">
                    <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                    <template v-slot:[`item.theme`]="{ item }">
                        <div class="elipsis-text-wrap">
                            <v-common-chip v-if="item.iscommon === true" :document-type="item.doctypeof"/>
                            <v-doc-type-chip :type="item.doctypeof" :text="item.doctype"/>
                            <div class="etw-text-container">
                                <div class="etw-text" v-tooltip.top-center="item.theme">
                                    {{item.theme}}
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.regdate`]="{ item }">
                        {{ formatDate(item.regdate) }}
                    </template>

                    <template v-slot:[`item.createdate`]="{ item }">
                        {{ formatDate(item.createdate) }}
                    </template>

                    <template v-slot:[`item.draftstatus`]="{ item }">
                        <StatusChip :status="avaibleStatuses[$helpers.getDraftStatus(item.draftstatus)]"/>
                    </template>

                    <template v-slot:[`footer.prepend`]>
                        <v-pagination
                            v-model="options.page"
                            :length="paginationPageCount"
                            :total-visible="$listPaginationTotal"
                            color="teal"
                        ></v-pagination>
                    </template>

                </v-data-table>
            </v-col>

        </v-row>

    </div>
</template>

<script>
import _ from 'lodash';
import { httpAPI } from "@/api/httpAPI";
import { mapActions } from 'vuex';

import Toolbar from '@/components/documents/Toolbar.vue';
import SearchPanel from '@/components/documents/SearchPanel.vue';
import FilterPanel from '@/components/documents/FilterPanel.vue';
import StatusChip from '@/components/global/chips/StatusChip';

export default {
    name: "Projects",
    columnIndex:  {
        'createdate': 3, 
        'theme': 4, 
        'regnumber': 6, 
        'regdate': 7, 
        'version': 8
    },
    components: {
        Toolbar,
        SearchPanel,
        FilterPanel,
        StatusChip
    },
    computed: {
        options: {
            get: function() {
                return this.$store.getters['correspondence/projects/getTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('correspondence/projects/SET_TABLE_OPTION', newValue);
            }
        },
        search: {
            get: function() {
                return this.$store.getters['correspondence/projects/getTableSearch'];
            },
            set: _.debounce(function(v) {
                this.$store.commit('correspondence/projects/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('correspondence/projects/SET_TABLE_SEARCH', v);
            }, 500)
        },
        category: {
            get: function() {
                return this.$store.getters['correspondence/projects/getCategory'];
            },
            set: function(newValue) {
                this.$store.commit('correspondence/projects/SET_CATEGORY', newValue);
                this.$store.commit('correspondence/projects/SET_TABLE_OPTION_PAGE', 1);
            }
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search,
                MyDraftStatus: this.category,
            }
        },
        headers() {
            const headers = [
                { text: this.$t("Номер"), value: 'regnumber', width: '150px' },
                { text: this.$t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: this.$t("Краткое_содержание"), value: 'theme' },
                { text: this.$t("Дата_создания"), value: 'createdate', width: '150px' },
                { text: this.$t("Статус"), value: 'draftstatus', width: '150px', sortable: false }
            ];

            if (this.filter.MyDraftStatus == "5") {
                return headers.filter(h => !['createdate', 'draftstatus'].includes(h.value));
            }

            if (this.filter.MyDraftStatus == "-1" || this.filter.MyDraftStatus == "2") {
                return headers.filter(h => !['regdate'].includes(h.value));
            }

            return headers.filter(h => !['regdate', 'draftstatus'].includes(h.value));
        },
        avaibleStatuses() {
            return this.$store.getters['documents/references/GetAvaibleStatuses'];
        }
    },
    data () {
        return {
            collection: "Projects",
            loading: true,
            total: 0,
            projects: [],

            filterDataSource: null,

            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        async onToolbarClick (event, btn) {
            this[btn.Action]();
        },
        async getData(isRefresh = false)
        {
            this.$eventBus.$emit('update_correspondents_counters');
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
            
            this.setOverlayVisible({ visible: true });    

            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.projects = [];
            this.loading = true;            

            let response = await httpAPI({
                url: `api/correspondence/list?collection=${this.collection}&filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET'
            });

            this.total = response?.data?.Payload?.total ?? 0;
            this.projects = response?.data.Payload?.data ?? [];
            
            this.loading = false;
            this.setOverlayVisible({ visible: false });
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, MyDraftStatus } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "createdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 3;

            return { MyDraftStatus, SearchStr, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage };
        },
        formatDate (value) {
            if (!value)
                return '';

            return this.$moment(value).format('DD.MM.YYYY');
        },
        async Refresh() {

            let filterResponse = await httpAPI({
                url: `api/correspondence/filter?collection=${this.collection}`,
                method: 'GET'
            });
            
            if (filterResponse) {
                var filter = filterResponse.data.Payload.Filter.Items.find(x => x.Value == this.collection);
                this.filterDataSource = { Filter: filter?.Items }
            }

            await this.getData(true);
        }
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        },
    },    
    async created() {
        this.setOverlayVisible({ visible: true });

        let response = await httpAPI({
            url: `api/actions/collection?type=Documents.Draft`,
            method: 'GET'
        });

        if (response) {
            let filterResponse = await httpAPI({
                url: `api/correspondence/filter?collection=${this.collection}`,
                method: 'GET'
            });
            
            if (filterResponse) {
                var filter = filterResponse.data.Payload.Filter.Items.find(x => x.Value == this.collection);
                this.filterDataSource = { Filter: filter?.Items }
            }

            this.$store.commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu });
        }        

        this.setOverlayVisible({ visible: false });
    },
}
</script>